import React from 'react'

import ToffImg from '../../assets/images/logo.webp'

const LoadingSpinner = () => {
    return (
        <main>
            <section id="main__section" className="section__brick ">
                <div className="toff__spinner-section">
                    <img src={ToffImg} alt="Toff" className="toff__spinner-img" />
                </div>
            </section>
        </main>

    )
}

export default LoadingSpinner