import React from 'react';
import { Link } from "react-router-dom";

// Import images

import HoleIndexFooter from "../../assets/images/hole_index_footer.webp";
import HolePlanningFooter from "../../assets/images/hole_planning_footer.webp";
import HoleToffibotFooter from "../../assets/images/hole_toffibot_footer.webp";
import HoleAventureFooter from "../../assets/images/hole_aventure_footer.webp";
import HoleChatFooter from "../../assets/images/hole_chat_footer.webp";

import LogoInstagram from "../../assets/images/logo_instagram.webp";
import LogoDiscord from "../../assets/images/logo_discord.webp";
import LogoTwitch from "../../assets/images/logo_twitch.webp";
import LogoContact from "../../assets/images/logo_contact.webp";

const Footer = (props) => {

    return (
        <footer>
            <div className="sitemap">
                <div className="sitemap__links">
                    <img className="sitemap__links-picture" src={HoleIndexFooter} alt="Activités : gaming, développement, 3D" />
                    <Link to="/" >
                        <p className="contour sitemap__links-title">
                            Accueil
                        </p>
                    </Link>

                    <Link to="/#presentation">
                        Présentation
                    </Link>

                    <Link to="/#activities" >
                        Activités
                    </Link>
                    <Link to="/rendus" >
                        Rendus
                    </Link>
                    <Link to="/games" >
                        Jeux
                    </Link>
                </div>
                <div className="sitemap__links">
                    <img className="sitemap__links-picture" src={HolePlanningFooter} alt="Planning des streams" />
                    <Link to="/planning" >
                        <p className="contour sitemap__links-title">Planning</p>
                    </Link>
                    <Link to="/planning#planning" >
                        Prochains streams
                    </Link>
                </div>
                <div className="sitemap__links">
                    <img className="sitemap__links-picture" src={HoleToffibotFooter} alt="Logo du Toffibot" />
                    <Link to="/toffibot" >
                        <p className="contour sitemap__links-title">Toffibot</p>
                    </Link>
                    <Link to="/toffibot#presentation" >
                        Présentation
                    </Link>
                    <Link to="/toffibot#commands" >
                        Les commandes
                    </Link>
                </div>
                <div className="sitemap__links">
                    <img className="sitemap__links-picture" src={HoleChatFooter} alt="Stats et quotes du chat Twitch" />
                    <Link to="/viewers" >
                        <p className="contour sitemap__links-title">Viewers</p>
                    </Link>
                    <Link to="/viewers#citations" >
                        Citations
                    </Link>
                    <Link to="/viewers#tofficoins" >
                        Tofficoins
                    </Link>
                    <Link to="/viewers#user" >
                        Stats
                    </Link>
                    <Link to="/viewers#classement" >
                        Classement
                    </Link>
                    <Link to="/suggestions" >
                        Suggestions
                    </Link>
                </div>
                <div className="sitemap__links">
                    <img className="sitemap__links-picture" src={HoleAventureFooter} alt="Jeu de rôle" />
                    <Link to="/aventure" >
                        <p className="contour sitemap__links-title">Aventure</p>
                    </Link>
                    <Link to="/aventure#aventure-presentation" >
                        Présentation
                    </Link>
                    <Link to="/aventure#character" >
                        Ton personnage
                    </Link>
                    <Link to="/aventure#aventure-classement" >
                        Classement
                    </Link>
                </div>
            </div>
            <div className="social">
                <a
                    href="https://www.instagram.com/jesuiskristoff_"
                    className="social-link"
                    target="_blank"
                    aria-label="Mon Instagram"
                    rel={"noopener noreferrer"}
                >
                    <img src={LogoInstagram} alt="Logo Instagram" className="social_pictures" />
                </a>
                <a
                    href="https://discord.gg/7KzhDBT2AZ"
                    className="social-link"
                    target="_blank"
                    aria-label="Mon Discord"
                    rel={"noopener noreferrer"}
                >
                    <img src={LogoDiscord} alt="Logo Discord" className="social_pictures" />
                </a>
                <a
                    href="https://www.twitch.tv/jesuiskristoff"
                    className="social-link"
                    target="_blank"
                    aria-label="Ma chaîne Twitch"
                    rel={"noopener noreferrer"}
                >
                    <img src={LogoTwitch} alt="Logo Twitch" className="social_pictures" />
                </a>
                <a
                    href="mailto:contact@jesuiskristoff.fr"
                    className="social-link"
                    target="_blank"
                    aria-label="Envoyez-moi un mail"
                    rel={"noopener noreferrer"}
                >
                    <img src={LogoContact} alt="Me contacter" className="social_pictures" />
                </a>
            </div>
            <p className="copyright">
                <span className="copyright-text">Copyright</span>
                <span className="copyright-logo">©</span>
                <span id="year">{new Date().getFullYear() === 2022 ? " 2022 " : " 2022-" + new Date().getFullYear() + " "}</span>
                - Karen P. & Benjamin G. - Tous droits réservés
            </p>
        </footer>
    );

};

export default Footer;