import React from 'react';
import { Link } from "react-router-dom";

// Import images
import Logo from '../../assets/images/logo.webp';
import DiscordLogin from './discordLogin';

const Header = (props) => {

    const { userData } = props;

    return (
        <header>
            <nav className="hamburger-menu">
                <input id="menu__toggle" type="checkbox" title='Bouton pour afficher la barre de navigation.' />
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span></span>
                </label>
                <div className="menu__box">
                    <div className="menu__items">
                        <Link
                            to="/"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Accueil
                        </Link>
                        <Link
                            to="/aventure"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Aventure
                        </Link>
                        <Link
                            to="/games"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Jeux
                        </Link>
                        <Link
                            to="/planning"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Planning
                        </Link>

                        <Link
                            to="/rendus"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Rendus
                        </Link>

                        <Link
                            to="/suggestions"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Suggestions
                        </Link>

                        <Link
                            to="/toffibot"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Toffibot
                        </Link>
                        <Link
                            to="/viewers"
                            className="menu__item"
                            onClick={() => {
                                document.getElementById("menu__toggle").checked = false;
                            }}
                        >
                            Viewers
                        </Link>

                       {/* {
                            userData &&
                            <Link
                                to="/gestion-planning"
                                className="menu__item"
                                onClick={() => {
                                    document.getElementById("menu__toggle").checked = false;
                                }}
                            >
                                Gestion stream
                            </Link>

                        }*/}
                        {
                            userData &&
                            <Link
                                to="/raid"
                                className="menu__item"
                                onClick={() => {
                                    document.getElementById("menu__toggle").checked = false;
                                }}
                            >
                                Raid
                            </Link>

                        }
                        {
                            (userData && userData.highestRole.toLowerCase() === "admin") &&
                            <Link
                                to="/post"
                                className="menu__item"
                                onClick={() => {
                                    document.getElementById("menu__toggle").checked = false;
                                }}
                            >
                                Post
                            </Link>

                        }
                    </div>
                </div>
            </nav>
            <div className="header__discord">
                {
                    (userData && userData.discordId) ?
                        <DiscordLogin type='logout' />
                        :
                        <DiscordLogin type='login' />
                }
                <div className="header__discord__profile-picture">
                    {
                        (userData && userData.avatar) ?
                            <img src={"https://cdn.discordapp.com/avatars/" + userData.discordId + "/" + userData.avatar + ".png"} alt="Avatar" />
                            :
                            <img src={Logo} alt="Logo de la chaîne Twitch de jesuiskristoff" />
                    }
                </div>
            </div>
        </header>
    )
}

export default Header