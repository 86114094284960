import React, {lazy, Suspense, useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Footer from './components/Global/Footer';
import Header from './components/Global/Header';

import LoadingSpinner from './components/Global/LoadingSpinner';

const Home = lazy(() => import('./pages/Home'));
const Aventure = lazy(() => import('./pages/Aventure'));
const Chatting = lazy(() => import('./pages/Chatting'));
const Planning = lazy(() => import('./pages/Planning'));
const Toffibot = lazy(() => import('./pages/Toffibot'));
const Viewers = lazy(() => import('./pages/Viewers'));
const Post = lazy(() => import('./pages/Post'));
const Raid = lazy(() => import('./pages/Raid'));
const Suggestions = lazy(() => import('./pages/Suggestions'));
const Creatif = lazy(() => import('./pages/Creatif'));
const CreatifPost = lazy(() => import('./pages/CreatifPost'));
const NotFound = lazy(() => import('./pages/Error'));
const Games = lazy(() => import('./pages/Games'));
const GestionPlanning = lazy(() => import("./pages/GestionPlanning"));

const App = () => {
    const {pathname, hash, key} = useLocation();

    const [userData, setUserData] = useState();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView(true);
                }
            }, 0);
        }
    }, [pathname, hash, key]);


    useEffect(() => {
        fetch("/api/discord/userinfo")
            .then((res) => res.json())
            .then((res) => {
                setUserData(res);
            })
            .catch((err) => {
                console.log(err);
                setUserData(false);
            });
    }, []);


    return (
        <>
            <Header
                userData={userData}
            />
            <Suspense fallback={<LoadingSpinner/>}>
                <Routes>
                    {
                        ['/', '/index'].map((path, index) => (
                            <Route
                                key={index}
                                path={path}
                                element={
                                    <Home
                                        title="Accueil"
                                        description="Présentation de la chaîne jesuiskristoff."
                                    />
                                }
                            />
                        ))
                    }

                    <Route
                        path="/aventure"
                        element={
                            <Aventure
                                title="L'aventure"
                                description="Informations diverses sur l'aventure RPG disponible sur le chat du streamer Twitch jesuiskristoff."
                            />
                        }
                    />

                    <Route
                        path="/chatting"
                        element={
                            <Chatting
                                title="Chatting"
                                description="Page de gestion du module de chatting."
                            />
                        }
                    />

                    <Route
                        path="/planning"
                        element={
                            <Planning
                                title="Planning des streams"
                                description="Planning de la chaîne jesuiskristoff."
                            />
                        }
                    />

                    {/*<Route
                        path="/gestion-planning"
                        element={
                            <GestionPlanning
                                title="Planning des streams"
                                description="Planning de la chaîne jesuiskristoff."
                                userData={userData}
                            />
                        }
                    />
                    */}

                    <Route
                        path="/toffibot"
                        element={
                            <Toffibot
                                title="Toffibot"
                                description="Page du bot Twitch Toffibot"
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="/viewers"
                        element={
                            <Viewers
                                title="Espace viewer"
                                description="Informations diverses sur le chat Twitch et les viewers de jesuiskristoff"
                            />
                        }
                    />

                    <Route
                        path="/rendus"
                        element={
                            <Creatif
                                title="Soyons Créatif !"
                                description="La page des rendus 3D du streamer jesuiskristoff"
                            />
                        }
                    />

                    <Route
                        path="/rendu/:postId"
                        element={
                            <CreatifPost/>
                        }
                    />

                    <Route
                        path="/suggestions"
                        element={
                            <Suggestions
                                title="Les streamers que je recommande"
                                description="Les chaînes twitch recommandées par jesuiskristoff"
                            />
                        }
                    />

                    <Route
                        path="/post"
                        element={
                            <Post
                                title="Admins - Post"
                                description="Poster les rendus des streams"
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="/raid"
                        element={
                            <Raid
                                title="Propositions de raid"
                                description="Liste de streams à raid en fonction des catégories, du nombre du viewers, du nombre de followers etc."
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="/games"
                        element={
                            <Games
                                title="Liste des jeux"
                                description="Liste des jeux streamés par jesuiskristoff"
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <NotFound
                                title="Page non trouvée"
                                description="Cette page indique que la page demandée n'existe pas"
                            />
                        }
                    />
                </Routes>
            </Suspense>
            <Footer/>
        </>
    );
};


export default App