import React from 'react';

import { FaDiscord } from 'react-icons/fa';

const discordLogin = (props) => {

    const { type } = props;

    return (
        <>
            {
                type === 'login' ? (
                    <a className="header__discord__connect" href='/api/discord/auth'>
                        <p className="header__discord__connect-text">
                            Connexion
                        </p>
                        <p className="header__discord__connect-logo">
                            <FaDiscord
                                className='fa-discord'
                                onMouseEnter={(e) => {
                                    // edit header__discord__connect class
                                    e.target.parentElement.parentElement.classList.add('hover');
                                }}
                                onMouseLeave={(e) => {
                                    // edit header__discord__connect class
                                    e.target.parentElement.parentElement.classList.remove('hover');
                                }}
                            />
                        </p>
                    </a>
                ) : (
                    <a className="header__discord__connect" href='/api/discord/auth/logout'>
                        <p className="header__discord__connect-text">
                            Connexion
                        </p>
                        <p className="header__discord__connect-logo">
                            <FaDiscord
                                className='fa-discord'
                                onMouseEnter={(e) => {
                                    // edit header__discord__connect class
                                    e.target.parentElement.parentElement.classList.add('hover');
                                }}
                                onMouseLeave={(e) => {
                                    // edit header__discord__connect class
                                    e.target.parentElement.parentElement.classList.remove('hover');
                                }}
                            />
                        </p>
                    </a>
                )
            }
        </>
    )
}

export default discordLogin